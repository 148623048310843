import {Pages}                   from "./Pages";
import React                     from 'react';
import ReactDOM                  from "react-dom";
import {PersonDependentDropList} from "../components/PersonDependentDropList";
import {PersonDependentElements} from "../enums/PersonDependentElements";

export class Students extends Pages {

    initPageHandlers() {

        super.initPageHandlers();
        this.data = window.diaryJsData;

        ReactDOM.render(
            <PersonDependentDropList
                entity={PersonDependentElements.STUDENT}/>,
            document.getElementById('list-search-form-component'),
        );

    }
}
